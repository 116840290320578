.homepage-portfolio {
  @extend .container;
  padding-top: 5rem;
  padding-bottom: 2rem;
  @include media-breakpoint-down(lg) {
    padding-bottom: 0; }
  &-row {
    @extend .row; }

  &-title {
    @extend .col-12;
    @extend .pb-5;
    text-align: center;

    &-heading {
      @extend .homepage-section-title; }

    &-subheading {
      @extend .homepage-section-subtitle; } }

  &-more {
    @extend .col-md-12;
    @extend .text-center;
    @extend .mt-5;

    &-button {
      @extend .btn;
      @extend .btn-primary; } } }
