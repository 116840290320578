.single-post {
    @extend .row;
    @extend .align-items-lg-center;
    &__thumbnail {
        @extend .col-lg-5;
        &-img {
            width: 100%;
            max-height: 12rem;
            object-fit: cover;
            object-position: center;
            border-radius: 1rem;
            filter: drop-shadow(0px 12px 24px rgba(46, 51, 56, 0.12)); } }
    &__content {
        @extend .col-lg-7;
        @include media-breakpoint-down(lg) {
            margin-top: 2rem; } }
    &__title {
        font-family: inherit;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.3em;
        @extend .mb-3; }
    &__excerpt {
        @extend .mb-3; } }
