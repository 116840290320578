.homepage-lpgalliance {
  @extend .container;
  padding-top: 3rem;
  padding-bottom: 5rem;
  @include media-breakpoint-down(lg) {
    padding-top: 2rem;
    padding-bottom: 0rem; }
  &--giftcard {
    padding-top: 6rem;
    padding-bottom: 0rem;
    @include media-breakpoint-down(lg) {
      padding-top: 4rem; } }

  &-row {
    @extend .row;
    @extend .align-items-lg-center;
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse!important; }
    &--reverse {
      @extend  .flex-row-reverse; } }

  &-content {
    @extend .col-lg-6;
    &__title {
      font-size: 2.5rem;
      line-height: 1.1em;
      @extend .mb-4;
      @include media-breakpoint-down(lg) {
        font-size: 1.75rem; } }
    &__subtitle {
      font-family: inherit;
      font-size: 1.125rem;
      line-height: 1.33em;
      font-weight: bold;
      @extend .mb-4; }
    &__text {
      @extend .mb-5;
      &-pragraph {
        @extend .mb-4; } } }

  &-thumbnail {
    @extend .col-lg-6;
    &__image {
      @extend .image-shadow;
      @include media-breakpoint-down(lg) {
        box-shadow: none;
        filter: none;
        border-radius: 0;
        width: 100vw;
        max-width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 2rem; } } } }
