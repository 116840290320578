.homepage-offer {
  @extend .container;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
    padding-bottom: 2rem; }

  &-row {
    @extend .row; }

  &-content {
    @extend .col-md-6;

    &-odd {
      @extend .order-2;
      @extend .order-md-1; }

    &-even {
      @extend .order-2; }

    &-title {
      @extend .homepage-section-title; }

    &-subtitle {
      @extend .homepage-section-subtitle; }

    &-text {
      @extend .homepage-section-text; }

    &-button {
      @extend .btn;
      @extend .btn-outline-primary; } }

  &-illustration {
    @extend .col-md-6;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      align-items: flex-start; }

    &-odd {
      @extend .order-1;
      @extend .order-md-2; }

    &-even {
      @extend .order-1;
      @extend .pr-5;
      justify-content: flex-end;
      @include media-breakpoint-down(sm) {
        justify-content: flex-start; }
      .homepage-offer-illustration-background {
        left: 0; } }



    &-background {
      @extend %pattern-background;
      width: 80%;
      position: absolute;
      z-index: -1;
      border-radius: $border-radius;
      height: 100%;
      right: 0;
      @include media-breakpoint-down(sm) {
        display: none; } }


    &-image {
      @extend .img-fluid;
      border: 6px solid white;
      border-radius: $border-radius;
      @include media-breakpoint-down(sm) {
        border: 0;
        margin-bottom: 1rem; } } } }
