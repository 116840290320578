.homepage-section {
  @extend .container-fluid;
  // border-bottom: 1px solid $gray-200

  &-title {
    font-size: 2.5rem;
    line-height: 1.1em;
    @extend  .mb-3; }
  &-subtitle {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5em;
    color: $color-secondary-light; }
  &-text {
    font-size: 1.1rem;
    line-height: 1.9rem;
    color: $gray-800;
    font-weight: 300; } }
