a {
  @include fast-transition(color); }

.image-shadow {
  border-radius: 1rem;
  filter: drop-shadow(0px .75rem 1.5rem rgba(46, 51, 56, 0.12));
  max-width: 100%;
  object-fit: cover;
  object-position: center; }

.icon-sax {
  width: 2rem;
  height: auto;
  display: inline-block;
  vertical-align: text-bottom;
  &-xxs {
    width: 1rem; }
  &-xs {
    width: 1.5rem; }
  &-sm {
    width: 3rem; }
  &-md {
    width: 4rem; }
  &-xl {
    width: 5rem; }
  &--path {
    &-black {
      &>path {
        fill: #000; } }
    &-white {
      &>path {
        fill: #fff; } } } }
