.homepage-introduction {
  padding: 0;
  @extend .container;

  &-row {
    @extend .row;
    @extend .align-items-lg-center;
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse; } }

  &-bg {
    @extend .col-lg-6;
    @include media-breakpoint-down(lg) {
      margin-top: 2rem; }
    &__thumbnail {
      width: 100%;
      object-fit: contain;
      object-position: center;
      @extend .image-shadow;
      @include media-breakpoint-down(md) {
        width: 100vw;
        max-width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
        filter: none;
        border-radius: 0; } } }
  &-content {
    @extend .col-12;
    text-align: center;
    @include media-breakpoint-down(md) {
      text-align: left; }
    &-subtitle {
      font-weight: 600;
      font-family: inherit;
      @extend .mb-3;
      color: #797D80;
      font-size: .75rem;
      @include media-breakpoint-up(md) {
        font-size: .875rem; } }

    &-title {
      font-size: 1.75rem;
      @include media-breakpoint-up(md) {
        font-size: 2.5rem; }
      line-height: 1.1em;
      @extend .mb-4; }

    &-text {
      @extend .mb-4;
      line-height: 1.5em;
      font-weight: 400; }


    &-sign {
      max-width: 17rem; } } }



