.content-page {

  &-section {
    @extend .container-fluid;
    border-bottom: 1px solid $gray-200; }

  &-container {
    @extend .container; }

  &-wrapper {
    @extend .col-md-10;
    @extend .offset-md-1; }

  &-header {
    @extend .row;
    @extend .mb-3;
    @extend .mt-3;
    padding-top: 3rem;
    padding-bottom: 3rem;

    &-content {
      @extend .col-md-8;
      @extend .offset-md-2;
      text-align: center;

      &-title {
        @extend .homepage-section-title; }

      &-subtitle {
        @extend .homepage-section-subtitle; } } }

  &-text {
    font-size: 1.1rem;
    font-weight: 300; }

  &-list {
    @extend .row;
    @extend .p-0;
    @extend .justify-content-center;
    list-style: none;

    &-item {
      @extend .col-md-4;
      @extend .border;
      @extend .m-3;
      @extend .p-0;
      max-width: 250px; }

    &-ordered {
      @extend .p-0;
      list-style: none;

      &-item {
        @extend .mb-4; }

      &-title {
        @extend .h4;
        font-weight: 600; } } }

  &-gallery {

    &-horizontal {
      float: right;
      max-width: 250px; }

    &-vertical {
      float: right;
      max-width: 200px; } }

  &-image {
    @extend .img-fluid;

    &-thumbnail {
      @extend .img-thumbnail;
      margin-top: 0; } } }

