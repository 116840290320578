.offer-selector {
  @extend .col-md-3;
  //background-color: #F1EBE4
  //border-radius: 5px
  border-right: 1px solid $gray-200;
  @include media-breakpoint-down(md) {
    display: none; }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none; }

  &-item {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    &-head {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      text-transform: uppercase;
      letter-spacing: 1px;

      &-link {
        color: $dark;
        font-weight: 700; } }

    &-subitem {
      padding-top: 0;
      padding-left: 1rem;
      padding-bottom: 0.6rem; }


    &-subpage {
      padding-top: 0;
      padding-left: 2rem;
      padding-bottom: 0.6rem; }

    &-link {
      font-weight: 600;
      color: $gray-600;
      &:before {
        content: url('/assets/images/icons/arrow-right-1.svg');
        display: none; }

      &-active {
        color: theme-color('primary');
        position: relative;
        font-weight: bold;
        &:before {
          display: block;
          position: absolute;
          left: -12px;
          top: 0; } } } } }

