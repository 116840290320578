.page-image {
  @extend .container-fluid;
  @extend .pl-0;
  @extend .pr-0;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  max-height: 400px;
  min-height: 250px;
  @include media-breakpoint-down(lg) {
    display: none; }


  img {
    width: 100%; }

  &-big {
    min-height: initial;
    max-height: initial;
    position: relative;
    padding-top: 5rem;
    margin-bottom: 10rem;
    overflow: visible;
    @include media-breakpoint-down(lg) {
      display: flex;
      padding-top: 0;
      max-height: initial; }
    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: -1;
      @include media-breakpoint-down(md) {
        height: 50%; } }
    &__container {
      position: relative;
      z-index: 1;
      top: 5rem;
      @include media-breakpoint-down(lg) {
        top: 2rem; } } }

  &-small {
    min-height: 250px;
    max-height: 20vh;
    @include media-breakpoint-down(lg) {
      display: none; } } }
