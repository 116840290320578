.homepage-recommendation {
    background: #EBE2D5;
    background: linear-gradient(180deg, #EBE2D5 0%, #DECFBA 100%);
    padding: 6rem 2rem;
    border-radius: .5rem;
    position: relative;
    @extend .container;
    z-index: 1;
    @include media-breakpoint-down(lg) {
        padding: 4rem 1rem 3rem; }
    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        border-top: 1px solid #fff;
        @include media-breakpoint-down(lg) {
            width: 100%; } }
    &__logotype {
        position: absolute;
        top: -1rem;
        left: -2rem;
        object-fit: contain;
        object-position: left bottom;
        z-index: -1; }
    &__header {
        @extend .d-flex;
        @extend .flex-column;
        @extend .align-items-center;
        @extend .mb-5;
        @include media-breakpoint-down(lg) {
            margin-bottom: 2rem!important; }
        &-heading {
            font-size: 2.5rem;
            line-height: 1.1em;
            @include media-breakpoint-down(lg) {
                font-size: 1.75rem; } }
        &-subheading {
            font-family: inherit;
            font-size: .875rem;
            font-weight: 600;
            color: #797D80;
            @extend .mb-3; } }
    &__wrapper {
        max-width: 46.5rem;
        margin: 0 auto; } }

