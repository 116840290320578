.offer-item {
  @extend .row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  clear: both;

  &-title {
    @extend .col-12;
    @extend .col-lg-10;

    &-heading {
 }      //font-weight: $font-weight-normal

    &-subheading {
      @extend .homepage-section-subtitle;
      font-size: 1.1rem;
      font-weight: 600;
      color: $color-primary; }

    &-active {
      @extend .p-1;
      background-color: yellow;
      border-radius: $border-radius;
      color: #000;
      @include slow-transition(all);
      &:before {
        content: "Wybrany zabieg";
        position: absolute;
        top: -19px;
        left: 15px;
        background-color: yellow;
        border-radius: 3px;
        font-size: 1rem;
        padding: 0.1rem 1rem; } } }

  &-content {
    @extend .col-12;
    @extend .col-lg-10;

    th {
      @extend .h4; }

    &-row {
      @extend .row; }

    &-name {
      @extend .col-7;
      @extend .col-md-7;

      &-text {
        padding-left: 0.5rem;
        font-size: 1rem;
        line-height: 1.8rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-weight: 400 !important;
        border-bottom: 1px solid $gray-200; } }

    &-image {
      max-width: 300px;
      float: left;
      margin-right: 1rem;
      margin-top: 1rem; }

    &-price {
      @extend .col-5;
      @extend .col-md-5;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-text {
        font-size: 1rem;
        font-weight: 400;
        border-bottom: 1px solid $gray-200; } } } }

