.fancy-page {


  &-section {
    @extend .container-fluid;
    border-bottom: 1px solid $gray-200; }

  &-container {
    @extend .container; }

  &-header {
    @extend .row;
    padding-top: 3rem;
    padding-bottom: 3rem;

    &-content {
      @extend .col-md-8;
      @extend .offset-md-2;
      text-align: center;

      &-title {
        @extend .homepage-section-title; }

      &-subtitle {
        @extend .homepage-section-subtitle; } } }

  &-text {
    font-size: 1.1rem;
    font-weight: 300; } }

