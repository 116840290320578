.homepage-brands {
  @extend .container-fluid;
  padding: 3rem 0;
  background-color: #2E3338;
  color: $color-secondary-light;
  $brand: &;
  &-row {
    @extend .row;
    &:first-child {
      #{$brand}-column {
        margin-top: 0; } } }

  &-column {
    @extend .col-md-6;
    margin-top: 1.5rem;
    @include media-breakpoint-up(md) {
      margin-top: 3rem; } }

  &-container {
    @extend .container; }

  &-image {
    @extend .img-fluid; } }
