.banner {
  border-top: 1px solid $gray-200;
  position: relative;

  &-image {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem; } }



  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &__image,
    &::before {
      position: absolute;
      top: 0;
      height: 100%; }

    &::before {
      content: '';
      width: 100%;
      left: 0;
      background: rgb(255,255,255);
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
      z-index: 1; }

    &__image {
      right: 0;
      object-fit: cover;
      object-position: left;
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 70%; } } }

  .banner-container {
    border: 1px solid black; } }

.face-treatment {
  &-banner {
    &-image {
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-repeat: no-repeat;
      background-position: right 17% bottom;
      background-size: contain; }
    &-content {
      &__role {
        @extend .description-bold; }
      &__title {
        @extend .heading-1; } } } }

