.category-teaser {
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    background-color: #F1EBE4;
    &__title {
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
        font-weight: bold; }
    &-grid {
        list-style-type: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(10rem, 1fr));
        grid-gap: 1rem;
        margin: 2rem 0 0;
        &__item {
            margin: 0; } } }
