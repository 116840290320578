.hours-table {
  @extend .table;
  @extend .table-borderless;
  @extend .table-sm;
  @extend .mb-0;
  &-big {
    font-size: 1.1rem;
    th {
      font-weight: 400; } }

  &-title {
    font-family: $headings-font-family;
    font-size: 1.25rem;
    @extend .pb-3;
    .far {
      font-size: 1.125rem; } }

  &-article {
    //box-shadow: none
    border-radius: 1rem;
    border: 1px solid $gray-300; }
  tbody {
    font-size: .875rem;
    th {
      padding: 0.3rem 0!important; }
    tr {
      th {
        color: map-get($theme-colors, 'secondary-light');
        font-weight: 400; }
      td,th {}
      border-bottom: 1px solid map-get($theme-colors, 'third');
      &:first-child {
        td,th {
          border-top: 1px solid map-get($theme-colors, 'third'); } } } } }
