.tag-page {

  &-section {
    @extend .container;
    @extend .pb-5;
    padding-top: 3rem;
    border-bottom: 1px solid $gray-200;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      padding-top: 1rem; } }

  &-container {
    @extend .row; }

  &-wrapper {
    @extend .col-md-12;
    padding-left: 0; }

  &-content {

    &-title {
      @extend .homepage-section-title;
      &:first-letter {
        text-transform: uppercase; } }

    &-subtitle {
      @extend .homepage-section-subtitle;
      color: $color-primary;
      font-size: 1.1rem; }

    h2 {
      margin: 0 0 1rem 0; }

    h3 {
      margin: 1rem 0 1rem 0; }

    p, li {
      line-height: 1.6; }

    li {
      padding-bottom: 1rem; }

    strong {
      font-weight: 700; }

    figure {
      a {
        cursor: zoom-in; } }

    &-toc {
      padding-left: 0;

      li {
        list-style-type: decimal;
        @extend .my-1; } } }


  &-services {
    background: #EBE2D5;
    background: linear-gradient(180deg, #EBE2D5 0%, #DECFBA 100%);
    padding: 2rem 2rem;
    border-radius: .5rem;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(lg) {
      padding: 4rem 1rem 3rem; }

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      border-top: 1px solid #fff;
      @include media-breakpoint-down(lg) {
        width: 100%; } }

    &-header {
      padding-bottom: 1rem;
      text-align: center;

      &-title {
        color: $body-dark; } }

    &-list {
      list-style: none;
      padding-left: 0; }

    &-item {
      @extend .mb-3;
      @extend .mx-0;
      @extend .row;
      @extend .justify-content-between;
      @extend .align-items-center;
      min-height: 120px;

      &-content {
        @extend .col-lg-8;
        padding-left: 0;

        &-title {
          @extend .homepage-section-title;
          color: $body-dark;
          margin: 0 0 0.3rem 0 !important;
          font-size: 1.3rem;
          font-weight: 700; }


        &-description {
          padding-top: 5px;
          padding-right: 10px;
          font-size: 0.875rem; } }

      &-link {
        width: 100%;
        text-align: center;
        display: block; }

      &-buttons {
        @extend .justify-content-center;
        @extend .col-lg-4;

        &-social {
          @extend .my-5;
          @extend .my-lg-1;
          @extend .col-lg-6;
          @extend .btn;
          @extend .btn-outline-primary; } } } } }


