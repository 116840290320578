.tags-page {
  @extend .container;
  @extend .pb-5;
  &-header {
    @extend .flex-column;
    @extend .d-flex;
    @extend .align-items-start;
    padding-top: 3rem;
    padding-bottom: 1rem;
    @include media-breakpoint-down(lg) {
      padding: 3rem 0 1rem; }

    &-title {
      @extend .homepage-section-title; }

    &-subtitle {
      @extend .offer-item-title-subheading; } } }

.tag-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EBE2D5;
  background: linear-gradient(180deg, #EBE2D5 0%, #DECFBA 100%);
  padding: 1rem;
  border-radius: .5rem;
  box-shadow: $box-shadow-sm;
  position: relative;
  z-index: 1;
  @include fast-transition(all);
  @include media-breakpoint-up(lg) {
    min-height: 94px; }


  &:hover {
    box-shadow: $box-shadow;
    transform: translateY(-.25rem);
    background: linear-gradient(180deg, #f8f0de 0%, #DECFBA 100%); }

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    @include media-breakpoint-down(lg) {
      width: 100%; } }

  &__logotype {
    position: absolute;
    top: -1rem;
    left: -2rem;
    width: 100px;
    object-fit: contain;
    object-position: left bottom;
    z-index: -1; }

  &__header {
    text-align: center;


    &-title {
      @extend .homepage-section-subtitle;
      color: $body-dark;
      font-weight: 600;
      font-size: 1.3rem;
      margin-bottom: 0; } } }
