.sidebar {

  &-container {
    @extend .mt-5;
    @extend .mb-3; }

  &-title {
    @extend .mb-4; }

  &-list {
    list-style: none;
    padding-left: 0;
    @extend .mb-5; }

  &-item {
    width: 100%;
    min-height: 120px;
    padding-inline-start: 0;
    @extend .mb-4;
    border-bottom: 1px solid $gray-200;

    &-thumbnail {
      @extend .row;

      &:hover {
        .sidebar-item-thumbnail-photo-image {
          opacity: 0.5; } }

      &:hover {
        .sidebar-item-thumbnail-photo-play {
          opacity: 1;
          z-index: 10; } }


      &-photo {
        @extend .col-3;
        padding-right: 0;

        &-link {
          display: block;
          position: relative;
          background: #000; }


        &-image {
          @extend .img-fluid;
          border-radius: 3px;
          transition: opacity 1s ease; }


        &-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          opacity: 0;
          transition: opacity 1s ease; } }



      &-title {
        @extend .col-9;

        &-heading {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          @extend .mt-md-0;
 }          //font-size: 1.4rem

        &-date {
          font-size: $font-size-sm;
          color: $gray-500; } } } }

  &-instagram {
    width: 100%;
    border: 0;
    overflow: hidden;
    min-height: 528px; } }


