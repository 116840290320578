$body-dark: #2F2F2F;
$body-color: #4A4A4A;
$color-primary: #b19971;
$color-primary-light: #E3D3B9;
$color-secondary: #2F2F2F;
$coffee: #4d4b4a;
$color-secondary-light: #585C60;
$color-third: #E2E4E5;

$border-radius: 3px;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$headings-font-family: 'Playfair Display', serif;
$headings-font-weight: 500;

$h1-font-size:                2.027rem ;
$h2-font-size:                1.802rem;
$h3-font-size:                1.424rem;
$h4-font-size:                1.266rem;
$h5-font-size:                1rem;
$h6-font-size:                $font-size-base ;

$btn-padding-x: 1.6rem;
$btn-padding-y: .7rem;
$btn-border-radius: 3px;
$btn-font-weight: 600;

$border-color: #e9ecef;

$body-color: $color-secondary;

$yiq-contrasted-threshold: 200;

$link-hover-decoration: none;

$navbar-nav-link-padding-x: 0.8rem;
$navbar-light-color: $color-secondary;
$navbar-light-hover-color: $color-primary;

$theme-colors: (
    "primary": $color-primary,
    "primary-light": $color-primary-light,
    "secondary": $color-secondary,
    "secondary-light": $color-secondary-light,
    "third": $color-third
);