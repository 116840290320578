.booking-cta {
  @extend .container-fluid;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #F3EDE8 0%, #FFFFFF 100%);

  &-grid {
    list-style-type: none;
    padding: 0;
    margin: 3rem auto 1rem 50%;
    transform: translateX(-50%);
    position: relative;
    width: 120vw;
    min-width: 25rem;
    max-width: 130rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {
      margin: 5rem auto 0 50%; }
    &__image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; }

    &__single {
      width: 18%;
      border-radius: 1.5rem;
      margin-right: 3%;
      filter: drop-shadow(0 .75rem 1.5rem rgba( 46, 51, 56, .12 ));
      overflow: hidden;
      display: flex;
      align-content: center;
      justify-content: center;
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-bottom: 75%; }

      &:last-child {
        margin-right: 0; }

      &:nth-child(3),
      &:nth-child(5), {
        margin-top: 2.5rem;
        @include media-breakpoint-up(lg) {
          margin-top: 5rem; } }

      &:nth-child(2),
      &:nth-child(6), {
        margin-top: 5rem;
        @include media-breakpoint-up(lg) {
          margin-top: 10rem; } }

      &:nth-child(1),
      &:nth-child(7), {
        margin-top: 15rem;
        display: none;
        @include media-breakpoint-up(lg) {
          display: flex; } } } }

  &-container {
    @extend .container;
    z-index: 999;

    .promo-banner {
      margin-bottom: -1.5rem; } }

  &-content {
    &__title,
    &__text {
      margin-bottom: 1.5rem; }

    &__title {
      font-size: 2rem;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;

      @include media-breakpoint-up(lg) {
        font-size: 3rem; } }

    &__text {
      line-height: 1.5em; }
    &__btn {
      margin-bottom: .75rem; }
    &__reservation {
      margin-top: .75rem;
      font-size: .75rem;
      color: #797D80; } }

  &-row {
    margin-bottom: 3rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 6rem; } } }
