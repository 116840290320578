.team-page {

  &-section {
    @extend .container;
    @extend .pb-5; }

  &-container {
    @extend .col-md-12; }

  &-header {
    @extend .flex-column;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;

    &-title {
      @extend .homepage-section-title; }

    &-subtitle {
      @extend .homepage-section-subtitle; } }

  &-list {
    @extend .row;
    list-style: none;
    padding-left: 0;

    &-item {
      @extend .col-md-6;
      @extend .col-lg-4;
      @extend .mb-4;
      padding: 16px 10px 0;
      height: 100%;
      &-photo {
        width: 100%;
        height: 236px;

        &-image {
          height: 100%;
          width: 100%;
          border: 2px solid white;
          @extend .rounded;

          img.thumb {
            height: 236px;
            width: 100%;
            object-fit: contain; } } }

      iframe {
        @extend .team-page-list-item-photo-image; }

      &-wrapper {
        @extend .d-md-flex;
        @extend .flex-column;
        @extend .justify-content-between;
        padding: 16px 2px;
        min-height: 220px;
        @include media-breakpoint-up(sm) {
          min-height: 310px; }
        @include media-breakpoint-up(xl) {
          min-height: 274px; } }

      &-title {
        margin: 0; }

      &-description {
        //word-spacing: -0.6px
        @extend .pt-2; }

      &-button {
        @extend .btn;
        @extend .btn-outline-primary;
        justify-content: center; } } } }


