.post {
  &-container {
    @extend .container;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center; }

  &-content {
    @extend .col-md-8;
    @extend .offset-md-2;

    &-image {
      @extend .img-fluid;
      margin-bottom: 3rem;
      border-radius: $border-radius;
      box-shadow: 2px 2px 40px $gray-300; }

    h1 {
      color: theme-color('primary'); }

    strong {
      font-weight: 700 !important; }

    ul {
      //text-align: left
      li {
        list-style-type: none; } } } }
