.homepage-recommendations {
  padding: 5rem 0;
  position: relative;
  background-color: #F1EBE4;
  z-index: 1;
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    padding: 4rem 0; }
  &__logo {
    position: absolute;
    top: -1rem;
    right: 0;
    z-index: -1;
    object-fit: contain;
    object-position: top right; }
  &-container {
      @extend .container; }
  &-row {
    @extend .row; }

  &-title {
    @extend .col-12;
    @extend .pb-5;
    text-align: center;


    &-heading {
      font-size: 2.5rem;
      line-height: 1.1em;
      font-weight: 400; }

    &-subheading {
      @extend .subheading; } }

  &-item {
    @extend .col-md-4;
    display: flex; }

  &-more {
    @extend .col-md-12;
    @extend .text-center;
    @extend .mt-5;

    &-button {
      @extend .btn;
      @extend .btn-primary; } } }
