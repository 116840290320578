.treatment-card {
  background-repeat: no-repeat;
  background-size: cover;
  height: 222px;
  border-radius: 30px;
  box-shadow: 0px 0px 3px $gray-100;
  @extend .pl-3;
  @extend .pt-3;
  @include medium-transition(all);
  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 10px $gray-300; }
  &-title {
    color: $coffee;
    font-weight: bold;
    font-size: $font-size-lg;
    width: 50%; } }


