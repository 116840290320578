.footer {
  @extend .container-fluid;
  background-color: #2E3338;
  border-top: 1px solid $color-secondary-light;

  svg {
    margin-right: .4rem;
    path {
      stroke: #fff; } }

  &-container {
    @extend .container;
    @extend .pt-5;
    @extend .pb-5;

    &-row {
      @extend .row; } }

  &-column {
    @extend .col-md-3;
    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
      text-align: center; }

    &-contact {
      text-align: center;
      .fas, .far {
        margin-right: 5px;
        color: theme-color('primary'); }

      &-social {
        width: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center; } }

    &-image {
      @extend .img-fluid;
      width: 60%;
      margin-bottom: 1rem;
      @include media-breakpoint-down(sm) {
        width: 30%; } }

    &-heading {
      color: white;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: 600; }

    &-text {
      color: white;
      font-size: 0.9rem;
      a {
        color: white;
        &:hover {
          color: theme-color('primary'); } } }

    &-list {
      @extend .list-group;
      @extend .list-group-flush;

      &-item {
        @extend .list-group-item;
        background-color: transparent;
        border: 0;
        padding: .5rem 0; } } } }




