.navbar-nav {
  @include media-breakpoint-down(lg) {
    padding-top: 1rem; } }

.nav-link {
  @include fast-transition(all);
  text-transform: uppercase;
  font-size: $font-size-sm;
  letter-spacing: 1px;
  font-weight: 600; }

#nav-download-app {
  @include media-breakpoint-up(lg) {
    display: none; } }
