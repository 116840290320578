.dropdown-submenu {
  position: relative;

  a.has-submenu:after {
    transform: rotate(-90deg);
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    border: 0;
    position: absolute;
    color: $gray-400;
    right: 1.5rem;
    top: 1.3rem;
    font-size: 0.6rem;
    vertical-align: inherit; }

  a.has-no-submenu:after {
    display: none; }

  .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem;
    @include media-breakpoint-down(lg) {
      margin-left: 1rem; } } }




