.breadcrumbs {
  @extend .container-fluid;
  //background: $gray-100
  border-bottom: 1px solid $gray-200;
  border-top: 1px solid $gray-200;

  &-container {
    @extend .container;

    .breadcrumb {
      margin: 0;
      background: white;
      padding: 0;

      &-item {
        @include fast-transition(all);
        color: $gray-500;
        &:hover {
          color: theme-color('primary'); }
        &:before {
          color: $gray-500; }
        &-active {
          color: $gray-600; } } } }

  &-content {
    @extend .col-12;
    @extend .col-md-9;
    @extend .col-lg-10;
    min-height: 45px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm; } }


  &-reservation {
    @extend .col-md-3;
    @extend .col-lg-2;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      display: none; } } }


