.service-description {
    $service: &;
    &-item {
        margin: 4rem 0;
        &:nth-child(2n) {
            #{$service}-row {
                flex-direction: row-reverse; } } }
    p {
        &:last-child {
            margin-bottom: 0; } }
    &-title {
        font-size: 1.125rem;
        margin-bottom: 1rem; }
    &__image {
        height: 250px;
        border-radius: 0.5rem;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 0.5rem; } }
    &__beforeAfter {
        height: 100%;
        &:not(&[data-bareservedstyle]) {
            img {
                display: none; } } } }
