.top-bar {
  @extend .container-fluid;
  @extend .p-0;
  border-bottom: 1px solid $gray-200;
  background-color: $gray-100;
  @include media-breakpoint-down(lg) {
    visibility: hidden;
    height: 0; }

  &-content {
    @extend .container;

    &-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 400;
      color: theme-color("primary");

      .icon-sax {
        margin-right: 5px; }

      a {
        color: #797D80;

        &:hover {
          color: #926e30; } } }


    &-socialicons {
      display: flex;
      align-items: center;

      &-icon {
        @include fast-transition(all);
        color: $gray-400;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $gray-200;
        &:last-child {
          border-right: 1px solid $gray-200; } } } }

  .whatsapp-green {
    color: #49c755;

    &:hover {
      color: #279232; } }

  &-lang-image {
    width: 17px;
    margin-right: 3px;
    vertical-align: baseline;
    box-shadow: 0 0 3px rgba(0,0,0,.15); } }

.dropdown-menu, .dropdown {
  a {
    color: #797D80; } }
