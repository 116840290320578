.recommendation-box {
  position: relative;
  color: $color-secondary-light;
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem 1.5rem;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;

  @include media-breakpoint-down(md) {
    margin-bottom: 1rem; }

  &-content {
    @extend .mb-3;
    &-icon {
      filter: drop-shadow(0 0 .15rem rgba(0,0,0,.1));
      @extend .mb-4;
      @include medium-transition(all);
      color: theme-color('primary');
      font-size: 1.5rem;
      &__star {
        fill: $color-primary;
        path {
          stroke: $color-primary; } } } }
  &-author {
    &-text {
      font-weight: 400;
      @extend .mb-0; } } }
