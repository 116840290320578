.page {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-weight: 300;
  @include media-breakpoint-down(lg) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; } }



