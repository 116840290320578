.navbar-brand {
  @extend .navbar-brand;
  width: 170px;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0,0,0,.14);
  margin-top: -9px;
  background-color: white;
  position: absolute;
  left: 0;
  z-index: 1;
  @include media-breakpoint-down(lg) {
    width: 96px;
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 4px; }

  &-image {
    @extend .img-fluid; } }
