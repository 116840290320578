.navbar-quick-contact {
  @extend .row;
  font-size: $font-size-sm;
  @include media-breakpoint-up(md) {
    display: none; }

  .whatsapp-green {
    color: #49c755;
    &:hover {
      color: #279232; } }

  &-address {
    @extend .col-10;
    @extend .pb-2;
    @extend .pt-2;
    text-align: center;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    .fab, .far, .fas, svg {
      margin-right: 5px; } }

  &-phone {
    @extend .col-6;
    @extend .pb-2;
    @extend .pt-2;
    display: flex;
    align-items: center;
    justify-content: center;

    .fab, .far, .fas {
      margin-right: 5px; }

    + .navbar-quick-contact-phone {
      border-left: 1px solid $gray-200; } } }
