// Do not delete 2 lines above.
// Reason: https://jekyllrb.com/docs/assets/

@import "source/variables";

@import "./bootstrap/scss/functions";
@import "./bootstrap/scss/variables";
@import "./bootstrap/scss/mixins";
@import "./bootstrap/scss/root";
@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/type";
@import "./bootstrap/scss/images";
//@import "../bootstrap/scss/code"
@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/tables";
//@import "../bootstrap/scss/forms"
@import "./bootstrap/scss/buttons";
@import "./bootstrap/scss/transitions";
@import "./bootstrap/scss/dropdown";
//@import "../bootstrap/scss/button-group"
//@import "../bootstrap/scss/input-group"
//@import "../bootstrap/scss/custom-forms"
@import "./bootstrap/scss/nav";
@import "./bootstrap/scss/navbar";
@import "./bootstrap/scss/card";
@import "./bootstrap/scss/breadcrumb";
//@import "../bootstrap/scss/pagination"
@import "./bootstrap/scss/badge";
//@import "../bootstrap/scss/jumbotron"
//@import "../bootstrap/scss/alert"
//@import "../bootstrap/scss/progress"
//@import "../bootstrap/scss/media"
@import "./bootstrap/scss/list-group";
@import "./bootstrap/scss/close";
@import "./bootstrap/scss/modal";
//@import "../bootstrap/scss/tooltip"
//@import "../bootstrap/scss/popover"
@import "./bootstrap/scss/carousel";
@import "./bootstrap/scss/utilities";
@import "./bootstrap/scss/print";


@import "source/mixins";
@import "source/buttons";
@import "source/typography";
@import "source/placeholders";

@import "layout/body";
@import "layout/others-general";
@import "layout/headings-paragraphs";
@import "layout/header";
@import "layout/footer";
@import "layout/page-404";
@import "layout/post";

@import "layout/homepage/homepage-section";
@import "layout/homepage/homepage-introduction";
@import "layout/homepage/homepage-offer";
@import "layout/homepage/homepage-recommendations";
@import "layout/homepage/homepage-posts";
@import "layout/homepage/homepage-philosophy";
@import "layout/homepage/homepage-brands";
@import "layout/homepage/homepage-lpgalliance";
@import "layout/homepage/homepage-promo-photo";
@import "layout/homepage/homepage-portfolio";
@import "layout/homepage/homepage-blog";
//@import "layout/homepage/homepage-pwa"
@import "layout/homepage/homepage-recommendation";

@import "layout/pages/page";
@import "layout/pages/offer-page";
@import "layout/pages/fancy-page";
@import "layout/pages/content-page";
@import "layout/pages/article-page";
@import "layout/pages/tag-page";
@import "layout/pages/blog-page";
@import "layout/pages/team-page";

@import "components/top-bar";
@import "components/navbar";
@import "components/navbar-brand";
@import "components/navbar-collapse";
@import "components/navbar-nav";
@import "components/page-image";
@import "components/image-card";
@import "components/hours-table";
@import "components/dropdown-submenu";
@import "components/navbar-dropdown-menu";
@import "components/promo-banner";
@import "components/recommendation-box";
@import "components/post-card";
@import "components/dark-well";
@import "components/breadcrumbs";
@import "components/img-border-behind";
@import "components/booking-cta";
@import "components/navbar-toggler";
@import "components/navbar-quick-contact";
@import "components/map";
@import "components/offer-selector";
@import "components/offer-item";
@import "components/gallery-grid";
@import "components/rounded-video";
@import "components/instagram";
@import "components/img-thumbnail";
@import "components/sidebar";
//@import "components/pwa-modal"
//@import "components/ios-pwa-modal"
@import "components/treatment-card";
@import "components/banner";
@import "components/single-post";
@import "components/recommended-boxes";
@import "components/service-description";
@import "components/category-teaser";
@import "components/offer-lifted-item";
@import "components/tag-card";

@import "plugins/jquery.fancybox.min";
