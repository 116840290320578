.offer-lifted-item {
    background-color: #F1EBE4;
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0; }
    &-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(10rem,1fr));
        grid-gap: 2rem;
        padding: 0;
        margin: 2rem 0 0 0;
        list-style: none;
        &__image {
            border-radius: 0.5rem;
            width: 100%;
            height: 9.5rem;
            object-fit: cover;
            object-position: center; } }
    &-title {
        &-heading {
            color: #000;
            font-size: 1.125rem;
            margin-bottom: 0.75rem; }
        &-subheading {
            color: #585C60;
            font-size: 0.85rem;
            margin-bottom: 0.75rem; } } }
