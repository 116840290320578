.post-card {
  @extend .card;
  background: transparent;
  margin-top: 1.5rem;
  border: none;

  &-photo {
    position: relative;
    flex: 0.5;
    @extend %flex-centered-content;

    &:after {
      content: ' ';
      width: 80%;
      height: 110%;
      position: absolute;
      @extend %pattern-background;
      z-index: -1;
      right: -1%;
      border-radius: $border-radius;
      @include media-breakpoint-down(sm) {
        display: none; } }

    &-link {
      @extend %flex-centered-content; }

    &-image {
      width: 80%;
      border: 6px solid white;
      border-radius: $border-radius-sm; } }

  &-content {
    flex: 0.5;
    @extend .card-body;

    &-title {
      text-align: center; }

    &-text {
      font-weight: 300;
      line-height: 1.8rem; } } }


