%pattern-background {
  background-color: theme-color('primary');
  background-image: url('assets/images/patterns/glamorous.svg');
  background-size: 6rem;
  color: white;
  &-light {
    background-color: theme-color('primary-light'); } }

%flex-centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
