@mixin fast-transition($elements) {
  -moz-transition:    0.1s linear $elements;
  -o-transition:      0.1s linear $elements;
  -webkit-transition: 0.1s linear $elements;
  transition:         0.1s linear $elements; }

@mixin medium-transition($elements) {
  -moz-transition:    0.2s linear $elements;
  -o-transition:      0.2s linear $elements;
  -webkit-transition: 0.2s linear $elements;
  transition:         0.2s linear $elements; }

@mixin slow-transition($elements) {
  -moz-transition:    0.5s linear $elements;
  -o-transition:      0.5s linear $elements;
  -webkit-transition: 0.5s linear $elements;
  transition:         0.5s linear $elements; }

@mixin div-same-size-as-background-img($url,$image-width, $image-height) {
  background-image: url($url);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: percentage($image-height / $image-width); }

@mixin div-same-size-as-background-no-img($image-width, $image-height) {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: percentage($image-height / $image-width); }
