.blog-page {

  &-section {
    @extend .container;
    @extend .pb-5; }

  &-container {
    @extend .col-md-12; }

  &-header {
    @extend .flex-column;
    @extend .d-flex;
    @extend .align-items-start;
    padding-top: 7rem;
    padding-bottom: 3rem;
    @include media-breakpoint-down(lg) {
      padding: 3rem 0 1rem; }

    &-title {
      @extend .homepage-section-title; }

    &-subtitle {
      @extend .homepage-section-subtitle; } }

  &-list {
    @extend .row;
    list-style: none;
    padding-left: 0;

    &-item {
      @extend .col-md-6;
      @extend .col-lg-4;
      @extend .pt-3;

      &:hover {
        .blog-page-list-item-image {
          opacity: 0; } }

      &:hover {
        .blog-page-list-item-play {
          opacity: 1;
          z-index: 10; } }

      &:hover {
        .blog-page-list-item-gif {
          visibility: visible;
          opacity: 0.8;
          @extend .rounded; }
        .blog-page-list-item-link__subtitle {
          opacity: 0; } }

      &-link {
        display: inline-block;
        position: relative;
        border-radius: 1rem;
        filter: drop-shadow(0px .75rem 1.5rem rgba(46, 51, 56, 0.12));
        overflow: hidden;
        background: rgba(0, 0, 0, 0.7);
        &__subtitle {
          position: absolute;
          left: 0;
          bottom: 1.5rem;
          color: $body-dark;
          background-color: #EBE2D5;
          font-size: .625rem;
          padding: .75rem;
          width: 100%;
          max-height: 2.25rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          opacity: 1;
          transition: opacity .3s ease; } }

      &-image {
        @extend .img-fluid;
        @extend .rounded;
        height: 16.5rem;
        object-fit: cover;
        opacity: 1;
        transition: opacity 1s ease; }

      &-gif {
        @extend .img-fluid;
        @extend .rounded;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 280px;
        object-fit: cover;
        opacity: 0;
        transition: opacity 1.5s ease; }


      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        height: 5rem;
        width: auto;
        &>path {
          fill: $color-third; } }

      &-wrapper {
        //@extend .px-3
        @extend .py-3;
        @extend .d-md-flex;
        @extend .flex-column;
        @extend .justify-content-between; }

      &-title {
        font-size: 1.125rem;
        font-family: inherit;
        color: $color-secondary;
        @include fast-transition(all);
        @extend .mb-2; }

      &-information {
        @extend .pt-2; }

      &-time {
        @extend .article-page-header-time; }

      &-author {
        @extend .article-page-header-time; }

      &-description {
        @extend .my-2;
        word-spacing: -0.6px;
        color: #797D80; } } }

  &-more {
    @extend .col-md-12;
    @extend .text-center;
    margin-top: 3rem;
    @include media-breakpoint-down(lg) {
      margin-top: 1rem; } }
  &-button {
    @extend .btn;
    @extend .btn-primary; } }




