.promo-banner {
  @extend .container;
  @extend %pattern-background;
  @extend .pt-3;
  @extend .pb-3;
  margin-bottom: -2rem;
  border-radius: $border-radius;
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem; }

  &-row {
    @extend .row; }

  &-title {
    @extend .col-lg-2;
    @extend %flex-centered-content;
    border-right: 1px solid rgba(255,255,255,.6);
    @include media-breakpoint-down(lg) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }

    &-heading {
      @extend .h3;
      color: white;
      font-family: "Nunito Sans", $font-family-sans-serif;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase; } }

  &-content {
    @extend .col-lg-7;
    @extend .pl-4;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
      text-align: center; }

    &-text {
      font-size: $h5-font-size;
      font-weight: 400;
      margin: 0; } }



  &-cta {
    @extend .col-lg-3;
    @extend %flex-centered-content;

    &-button {
      @extend .btn;
      @extend .btn-outline-white; } } }
