.homepage-blog {

  &-section {
    @extend .container-fluid; }

  &-container {
    @extend .container;
    padding-bottom: 5rem;
    @include media-breakpoint-down(lg) {
      padding-bottom: 2rem; } }

  &-header {
    @extend .blog-page-header;

    &-title {
      font-size: 2.5rem;
      @include media-breakpoint-down(lg) {
        font-size: 1.75rem; }
      @extend .mb-4; }

    &-subtitle {
      font-family: inherit;
      color: inherit;
      font-size: 1rem;
      line-height: 1.5em;
      @include media-breakpoint-down(lg) {
        font-size: .85rem;
        br {
          display: none; } } } }

  &-list {
    @extend .blog-page-list;

    &-item {
      @extend .blog-page-list-item;

      &-link {
        @extend .blog-page-list-item-link;
        &__subtitle {
          @extend .blog-page-list-item-link__subtitle; } }

      &-image {
        @extend .blog-page-list-item-image; }

      &-play {
        @extend .blog-page-list-item-play; }

      &-wrapper {
        @extend .blog-page-list-item-wrapper; }

      &-title {
        @extend .blog-page-list-item-title; }

      &-description {
        @extend .blog-page-list-item-description; } } }

  &-more {
    @extend .blog-page-more; }

  &-button {
    @extend .blog-page-button; } }
