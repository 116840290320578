strong,
.font-weight-bold {
  font-weight: 700; }

.heading-1 {
  font-size: 2rem;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  @include media-breakpoint-up(lg) {
    font-size: 3rem; } }

.subheading {
  font-weight: 600;
  font-size: 0.875rem;
  color: #797D80;
  font-family: inherit; }

.description-bold {
  font-weight: bold;
  font-size: .875rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem; } }
