.gallery-grid {
  column-width: 320px;
  width: 90%;
  max-width: 1100px;
  column-gap: 20px;
  margin: 0 auto;

  &-column {
    @extend .mt-2;
    @extend .mb-2;
    display: inline-block;

    img {
      @include medium-transition(all);
      filter: saturate(40%);
      transform: scale(1);

      &:hover {
        filter: saturate(100%);
        transform: scale(1.05); } } } }
