.homepage-philosophy {
  background-color: #fff;
  @extend .container;
  &-row {
    @extend .row;
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse; } }
  &-column {
    @extend .col-12;
    @extend .col-xl-6; }
  &-image {
    position: relative;
    height: 100%;
    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 30%;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include media-breakpoint-up(lg) {
        width: 30%;
        height: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
        right: 0;
        left: initial;
        transform: none; } }
    &__thumbnail {
      object-fit: cover;
      object-position: right;
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
      max-width: 100vw;
      height: 100%;
      opacity: .66;
      @include media-breakpoint-down(lg) {
        position: relative;
        right: initial;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        margin-top: 3rem;
        max-height: 20rem; } } }
  &-content {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .text-center;
    padding: 5.5rem 0;
    font-size: 1.25rem;
    line-height: 1.5em;
    @include media-breakpoint-down(lg) {
      padding: 4rem 0 2rem;
      font-size: 1rem; }
    &__text {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight; }
    &__logo {
      height: 7.75rem;
      object-fit: contain;
      object-position: center;
      @extend .mb-5; }
    &__text {
      @extend .mb-5; }
    &__buttons {
      @include media-breakpoint-down(lg) {
        align-self: baseline;
        width: 100%; }
      .btn {
        margin: .5rem .5rem 0; } } } }
