.article-page {

  &-section {
    @extend .container;
    @extend .pb-5;
    border-bottom: 1px solid $gray-200;
    overflow: hidden; }

  &-container {
    @extend .row; }

  &-wrapper {
    @extend .col-md-12;
    padding-left: 0; }

  &-header {
    padding-top: 3rem;
    padding-bottom: 1rem;
    @extend .col-md-8;
    @include media-breakpoint-down(lg) {
      padding-top: 1rem; }

    &-title {
      @extend .homepage-section-title; }

    &-time {
      height: 22px;
      font-size: 1rem;
      font-weight: 400;
      color: $gray-500;
      img {
        height: 22px;
        margin-bottom: 4px;
        margin-right: 2px; } } }

  &-video {
    @extend .col-md-12; }

  &-image {
    @extend .img-fluid;
    border-radius: 3px; }

  &-content {
    @extend .pt-3;

    &-body {
      h2 {
        margin: 2rem 0 1rem 0; }
      h3 {
        margin: 2rem 0 1rem 0; }
      p, li {
        line-height: 1.6; }
      li {
        padding-bottom: 1rem; }
      strong {
        font-weight: 700; }
      figure {
        a {
          cursor: zoom-in; } } }

    &-toc {
      padding-left: 0;
      li {
        list-style-type: decimal;
        @extend .my-1; } } }

  &-aside {
    @extend .col-md-4;
    @extend .pl-lg-4;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex; } }

  &-services {
    &-header {
      padding-bottom: 1rem; }

    &-title {
      @extend .homepage-section-title; }

    &-list {
      list-style: none;
      padding-left: 0; }

    &-item {
      @extend .mb-3;
      @extend .mx-0;
      @extend .row;
      @extend .justify-content-between;
      @extend .align-items-center;
      min-height: 120px;

      &-content {
        @extend .col-lg-8;
        padding-left: 0;

        &-title {
          @extend .homepage-section-subtitle;
          font-size: 1.5rem;
          margin: 0 0 0.3rem 0 !important; }

        &-description {
          padding-right: 10px;
          font-size: 0.875rem; } }

      &-link {
        @extend .btn;
        @extend .btn-outline-primary;
        @extend .justify-content-center;
        @extend .col-lg-4;
        max-width: 190px;

        &-social {
          @extend .my-5;
          @extend .my-lg-1;
          @extend .col-lg-6;
          @extend .btn;
          @extend .btn-outline-primary; } } } } }


