.navbar {
  margin-bottom: 0.5rem;
  justify-content: flex-end;
  @include media-breakpoint-down(lg) {
    justify-content: space-between; }
  .whatsapp-green {
    color: #49c755;
    &:hover {
      color: #279232; } }

  .nav-link.dropdown-toggle:after {
    color: black; } }
