.recommended-boxes {
    h2 {
        font-weight: bold;
        font-size: 1.125rem;
        margin-bottom: 1rem;
        color: #000; }
    &-list {
        list-style-type: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        grid-gap: 2rem; }
    &-item {
        padding: 1rem;
        background-color: #F1EBE4;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        &__btn {
            margin-top: auto; }
        &__image {
            height: 12.5rem;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 0.5rem; }
        &__content {
            padding: 1.5rem 0 0.5rem;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &-title {
                font-size: 1.125rem;
                margin-bottom: 0.75rem; } }
        p {
            font-size: 0.875rem; } } }
