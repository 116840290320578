.img-border-behind {
  margin-top: 2rem;
  position: relative;
  &:before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    border: 6px solid theme-color('primary');
    border-radius: $border-radius;
    left: -2rem;
    top: -2rem;
    z-index: -999999;
    @include media-breakpoint-down(md) {
      width: 122%; } } }
