.image-card {
  @extend .card;
  background-color: white;
  box-shadow: 2px 2px 12px rgba(0,0,0,.19);

  &-body {
    @extend .card-body;
    padding: 2rem; }

  &-title {
    @extend .card-title;
    @extend .mb-0;
    font-size: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem; } }

  &-text {
    @extend .card-text;
    @extend .mt-2;
    @extend .mb-2;
    line-height: 1.5em;
    font-weight: 600; }
  &-separator {
    border-bottom: 1px solid #E5E5E5;
    margin: 2rem 0;
    width: 100%;
    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      border-left: 1px solid #E5E5E5;
      margin: 0;
      width: auto; } } }

