.dark-well {
  @extend .container;
  @extend .p-5;
  border-radius: $border-radius;
  background-color: theme-color('secondary');


  &-row {
    @extend .row; }

  &-content {
    @extend .col-md-12;

    &-text {
      @extend .h3;
      @extend .pl-md-3;
      @extend .pr-md-3;
      color: white;
      text-align: center; } }

  &-cta {
    @extend .col-md-4;
    @extend .col-lg-3;
    @extend .pt-4;
    @extend .offset-md-2;
    @extend .offset-lg-3;
    + .dark-well-cta {
      @extend .offset-md-0; }

    &-button {
      @extend .btn;
      @extend .btn-block;
      @extend .btn-outline-white; } } }
