.offer-page {
  @extend .container;
  @extend .page;

  p, li {
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 400; }

  &-row {
    @extend .row; }

  &-content {
    @extend .col-12;
    @extend .col-lg-9;
    @extend .pl-lg-5; } }
