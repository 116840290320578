.nav-link.dropdown-toggle {
  &:after {
    content: url('/assets/images/icons/arrow-down.svg');
    font-weight: 900;
    transform: scale(0.6);
    position: absolute;
    top: 11px;
    border: 0;
    color: $gray-400;
    font-size: 0.6rem;
    vertical-align: inherit; } }

.navbar-dropdown-menu {
  border: 0;
  border-radius: 3px;
  @include media-breakpoint-up(xl) {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15); }


  .dropdown-item {
    @include fast-transition(all);
    text-transform: uppercase;
    font-size: $font-size-sm;
    padding-right: 2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    letter-spacing: 1px;
    color: $gray-600;

    &:hover {
      color: theme-color('secondary'); }

    &:focus {
      color: theme-color('secondary'); } } }


.dropdown-menu.fade {
  @include medium-transition(all);
  opacity: 0;
  pointer-events: none;
  margin-top: 0;
  @include media-breakpoint-up(xl) {
    display: block; } }


.show > .dropdown-menu.fade {
  pointer-events: auto;
  opacity: 1;
  @include media-breakpoint-up(lg) {
    margin-top: 0.4rem; } }


.show > .dropdown-submenu > .dropdown-menu.show.fade {
  pointer-events: auto;
  opacity: 1; }
