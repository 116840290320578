.homepage-posts {
  @extend .container;
  padding-top: 4rem;
  padding-bottom: 5rem;

  &-row {
    @extend .row;
    @extend .justify-content-center; }

  &-title {
    @extend .col-12;
    @extend .pb-4;
    text-align: center;

    &-heading {
      @extend .homepage-section-title; }

    &-subheading {
      @extend .homepage-section-subtitle; } }

  &-item {
    @extend .col-md-12; } }
