
.btn {
  text-transform: none;
  letter-spacing: 0.05em;
  font-size: .875rem;
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  &:not(.btn-outline-primary) {
    box-shadow: 0px 12px 24px rgba(46, 51, 56, 0.12); }
  $btn: &;
  svg,img {
    display: inline-block;
    vertical-align: text-top;
    margin-right: .5rem; }
  &-primary {
    color: $body-dark;
    &:hover,&:focus {
      color: $body-dark; } }
  &-outline-white {
    @include button-outline-variant(white);
    font-weight: 400; }
  &-white {
    @include button-variant(white,white);
    font-weight: 400; }
  &-primary {
    color: $coffee;
    @include button-variant($color-primary-light, darken($color-primary-light, 3%));
    border: 0;
    svg {
      transition: filter .3s ease; }
    &:hover,&:focus {
      svg {
        filter: brightness(0) invert(1); } } }
  &__arrow {
    margin: 0 0 0 .5rem; }
  &-icon {
    &--left {
      #{$btn}__arrow {
        margin: 0 0 0 .5rem; } }
    &--right {
      #{$btn}__arrow {
        margin: 0 .5rem 0 0; } } } }

// Remove box shadow for language switcher with has certain id
#languageDropdown {
  box-shadow: none; }
